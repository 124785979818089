import React from 'react'
import styled from 'styled-components/macro'
import { Installation } from '../../config-db'
import theme from '../../theme'
import { FilieresConfig } from '../../config'
import { formatValue } from '../../utils/format'
import FiliereDot from '../FiliereDot'
import { Filiere } from '../../types'

const NomInstallation = styled.div`
    color: ${theme.colors.Black};
    text-transform: uppercase;
`

const FiliereContainer = styled.div``

const FiliereLabel = styled.span`
    color: ${theme.colors.Black};
`

const Puissance = styled.div`
    color: ${theme.colors.Black};
    font-weight: bold;
    font-size: 115%;
`

export interface Props {
    installation: Installation
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const InfoBoxMapEntry: React.FunctionComponent<Props> = ({
    installation,
    className = '',
}) => {
    const { filiere, nominstallation, puissance } = installation
    return (
        <div className={className}>
            {/* Installations are aggregated in preprocessing. When an installation represents an aggregate, its name will be '' */}
            {nominstallation.length ? (
                <NomInstallation>{nominstallation}</NomInstallation>
            ) : null}
            <Puissance
                dangerouslySetInnerHTML={{
                    __html: formatValue(puissance, 'W'),
                }}
            ></Puissance>
            <FiliereContainer>
                <FiliereDot filiere={filiere} disabled={false} />
                <FiliereLabel>
                    {FilieresConfig[filiere as Filiere].display}
                </FiliereLabel>
            </FiliereContainer>
        </div>
    )
}

export default styled(React.memo(InfoBoxMapEntry))`
    ${FiliereDot} {
        margin-right: 0.3em;
        position: relative;
        top: 0.1em;
    }
`
