import React from 'react'
import styled from 'styled-components/macro'
import Modal from './Modal'

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
    onClose: () => void
}

const Component: React.FunctionComponent<Props> = ({
    onClose,
    className = '',
}) => {
    return (
        <Modal title="Méthodologie" className={className} onClose={onClose}>
            <p>
                L’objectif de cette datavisualisation est de représenter
                géographiquement les points d'injection en france métropolitaine
                de biométhane sur différentes mailles du territoire français
                ainsi que le type de site.
            </p>

            <p>
                Les mailles territoriales disponibles sont le territoire
                métropolitain, les régions et les départements.
            </p>
            <p>
                Les données sont issues du jeu de données « Points d'injection
                de biométhane en France en service » mis à disposition{' '}
                <a
                    href="https://opendata.reseaux-energies.fr/pages/accueil/"
                    target="blank_"
                >
                    sur la plateforme ODRE
                </a>
                .
            </p>

            <p>
                Pour information, en 2020, le gaz vert représente environ 1% de
                la consommation de gaz en France.
            </p>
        </Modal>
    )
}

export default styled(React.memo(Component))``
