export const wrapText = (text: string, numLines: number): Array<string> => {
    const charsPerLine = text.length / numLines
    const words = text.split(' ')
    const lines: Array<Array<string>> = []
    for (let i = 0; i < numLines; i++) {
        let line: Array<string> = []
        while (words.length && line.length + words[0].length < charsPerLine) {
            const word = words.shift()
            if (!word) {
                break
            }
            line.push(word)
        }
        lines.push(line)
    }
    const lastLine = lines.slice(-1)[0]
    words.forEach((word) => lastLine.push(word))
    return lines.map((line) => line.join(' '))
}

export const numberDisplay = (value: number) => {
    return `${value}`.replace('.', ',')
}
