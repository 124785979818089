import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import { CanvasDotsIndexedByFiliere as CanvasDotsByFiliere } from '../../store/MapsContainer'
import theme from '../../theme'
import { FiliereFilters } from '../../types'
import { filterCanvasDotsByFilieres } from '../../utils/map'
import { getCanvas2DContext } from '../../utils/react'
import Canvas from './Canvas'
import { useFiliereFiltersDiff } from './hooks'
import {
    cancelAnimation,
    renderInstallationsRadiusAnimated,
} from './map-canvas'

export interface Props {
    in: boolean
    width: number
    height: number
    canvasDotsByFiliere: CanvasDotsByFiliere
    filiereFilters: FiliereFilters
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const CanvasBigInstallations: React.FunctionComponent<Props> = ({
    in: in_,
    width,
    height,
    canvasDotsByFiliere,
    filiereFilters,
    className = '',
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const { filieresIn, filieresOut, filieresStay } = useFiliereFiltersDiff(
        filiereFilters,
        in_
    )
    useEffect(() => {
        const context = getCanvas2DContext(canvasRef)
        if (!context) {
            return
        }
        const animationState = renderInstallationsRadiusAnimated(
            context,
            {
                in: filterCanvasDotsByFilieres(canvasDotsByFiliere, filieresIn),
                out: filterCanvasDotsByFilieres(
                    canvasDotsByFiliere,
                    filieresOut
                ),
                stay: filterCanvasDotsByFilieres(
                    canvasDotsByFiliere,
                    filieresStay
                ),
            },
            filieresIn.length
                ? theme.transitions.mapsEnterDuration
                : theme.transitions.mapsExitDuration
        )
        return () => cancelAnimation(animationState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filieresIn, filieresOut, filieresStay])

    return (
        <Canvas
            width={width}
            height={height}
            canvasRef={canvasRef}
            className={className}
        />
    )
}

export default styled(React.memo(CanvasBigInstallations))`
    width: 100%;
    ${Canvas} {
        width: 100%;
        height: auto;
    }
`
