import {
    GroupedResultsGeneric,
    GroupedResultsPays,
    GroupedResultsDepartementOrRegion,
    GroupedType,
} from '../../store/MapsContainer'
import { TerritoryType } from '../../types'

export const territoryTypeToGroupedType = (territoryType: TerritoryType) =>
    territoryType === TerritoryType.PAYS
        ? GroupedType.PAYS
        : GroupedType.DEPARTEMENT_OR_REGION

export const mapGroupedResult = <In, Out>(
    groupedResultIn: GroupedResultsGeneric<In>,
    func: (
        value: In,
        group:
            | keyof GroupedResultsPays<In>['groups']
            | keyof GroupedResultsDepartementOrRegion<In>['groups']
    ) => Out
): GroupedResultsGeneric<Out> => {
    switch (groupedResultIn.type) {
        case GroupedType.PAYS:
            return {
                type: GroupedType.PAYS,
                groups: {
                    all: func(groupedResultIn.groups['all'], 'all'),
                    metro: func(groupedResultIn.groups['metro'], 'metro'),
                    corse: func(groupedResultIn.groups['corse'], 'corse'),
                },
            }
        case GroupedType.DEPARTEMENT_OR_REGION:
            return {
                type: GroupedType.DEPARTEMENT_OR_REGION,
                groups: {
                    all: func(groupedResultIn.groups['all'], 'all'),
                },
            }
    }
}

export const castGroupedResultPays = <T>(
    groupedResult: GroupedResultsGeneric<T>
): GroupedResultsPays<T> => {
    if (groupedResult.type !== GroupedType.PAYS) {
        throw new Error(`Unexpected type ${groupedResult.type}`)
    }
    return groupedResult
}

export const castGroupedResultDepartementOrRegion = <T>(
    groupedResult: GroupedResultsGeneric<T>
): GroupedResultsDepartementOrRegion<T> => {
    if (groupedResult.type !== GroupedType.DEPARTEMENT_OR_REGION) {
        throw new Error(`Unexpected type ${groupedResult.type}`)
    }
    return groupedResult
}
