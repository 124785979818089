import { createSelector } from 'reselect'
import { Installation } from '../config-db'
import { RootState } from '../store'

export const selectRawDataLoadStatus = (state: RootState) =>
    state.rawData.status

export const selectRawData = (state: RootState) => state.rawData.data

export const selectRawDataErrors = (state: RootState) => state.rawData.errors

export const selectWindowDimensions = (state: RootState) =>
    state.windowDimensions

export const selectRawDataFailsafe = (state: RootState) => {
    if (!state.rawData.data) {
        throw new Error('Expected state to contain data')
    }
    return state.rawData.data
}

export const selectDepartements = (state: RootState) =>
    selectRawDataFailsafe(state).departements

export const selectInstallations = (state: RootState) =>
    selectRawDataFailsafe(state).installations

export const selectDataDate = (state: RootState) => {
    return new Date(selectRawDataFailsafe(state).biomethaneMeta.dataDate)
}

export type InstallationsById = { [installationId: string]: Installation }

export const selectInstallationsById = createSelector(
    selectInstallations,
    (installations: Array<Installation>) => {
        const installationsDict: InstallationsById = {}
        installations.forEach((installation) => {
            installationsDict[installation.id] = installation
        })
        return installationsDict
    }
)

export type InstallationsByCommune = {
    [communeInsee: string]: Array<Installation>
}
