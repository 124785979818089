import React from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'
import logoSrc from '../images/logo-ORE-white-@2x.png'
import { ReactComponent as GradientSvg } from '../images/header-form.svg'
import { CSSTransition } from 'react-transition-group'
import { buildTransitionHelper } from '../core/animations'

const CSS_TRANSITION_CLASS_NAME = 'HeaderDesktop'
const GRADIENT_HEIGHT = 120

const GradientBackground = styled(GradientSvg)`
    position: relative;
    right: 5%;
    width: 95%;
    height: ${GRADIENT_HEIGHT}px;
`

// This is only to allow margin: auto to work on inner container
const InnerContainerMargin = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: -5%;
    left: 0;
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    @media (min-width: ${theme.dimensions.thresholdBigScreen}px) {
        /* Re-center logo and text */
        position: relative;
        right: 1.5em;
    }
    ${theme.mixins.appMarginsMixin}
`

const Logo = styled.a`
    height: 85%;
    display: block;
    img {
        height: 100%;
    }
`

const Title = styled.h1`
    color: ${theme.colors.White};
    font-size: 170%;
    @media (max-width: 1000px) {
        font-size: 140%;
    }
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const HeaderDesktop: React.FunctionComponent<Props> = ({ className = '' }) => {
    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                <GradientBackground />
                <InnerContainerMargin>
                    <InnerContainer>
                        <Logo href="https://www.agenceore.fr/" target="blank_">
                            <img src={logoSrc} alt="logo ORE" />
                        </Logo>
                        <Title>
                            Répartition des installations
                            <br />
                            de production de biométhane
                        </Title>
                    </InnerContainer>
                </InnerContainerMargin>
            </div>
        </CSSTransition>
    )
}

const { logoAndText, gradient } = theme.transitions.pageEnterSequence.Header
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(HeaderDesktop))`
    /* to allow positioning absolute of other elements */
    position: relative;

    ${appearTransition(
        [`${Logo}`, `${Title}`],
        [['opacity', '0', '1']],
        logoAndText[0],
        logoAndText[1]
    )}

    ${appearTransition(
        [`${GradientBackground}`],
        [['transform', 'translateY(-100%)', `translateY(0%)`]],
        gradient[0],
        gradient[1]
    )}
`
