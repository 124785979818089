import * as yup from 'yup'
import { DbSpec } from './core/db-core'
import {
    validateRowYup,
    identity,
    inflateODSExportedData,
} from './core/db-operations'
import { fetchJSON } from './core/requests'
import { GeometryCollection } from 'geojson'
import { Filiere } from './types'
import config from './config'

// TODO : make sure to fetch all rows
const BIOMETHANE_URL =
    'https://opendata.reseaux-energies.fr/api/v2/catalog/datasets/points-dinjection-de-biomethane-en-france/exports/json?limit=-1&pretty=false&timezone=UTC'
const BIOMETHANE_META_URL =
    'https://opendata.reseaux-energies.fr/api/v2/catalog/datasets/points-dinjection-de-biomethane-en-france?pretty=false&timezone=UTC&include_app_metas=false'
const DEPARTEMENTS_URL = config.ROOT_URL + '/production-data/departements.json'

const biomethaneMetaSchema = yup
    .object({
        dataDate: yup.number().required(),
    })
    .required()
export type BiomethaneMeta = yup.InferType<typeof biomethaneMetaSchema>

const departementSchema = yup
    .object({
        geo_shape: yup.object().required(),
        insee_dep: yup.string().required(),
        insee_reg: yup.string().required(),
        nom_reg: yup.string().required(),
        nom_dep: yup.string().required(),
    })
    .required()

export type Departement = yup.InferType<typeof departementSchema> & {
    geo_shape: GeometryCollection
}

const biomethaneSchema = yup
    .object({
        id: yup.string().required(),
        filiere: yup.string().required(),
        nominstallation: yup.string().required(),
        puissance: yup.number().required(),
        ville: yup.string().required(),
        insee_reg: yup.string().required(),
        insee_dep: yup.string().required(),
        reseau: yup.string().required(),
        coordonnees: yup
            .object({
                lat: yup.number().required(),
                lon: yup.number().required(),
            })
            .required(),
    })
    .required()

type BiomethaneBase = yup.InferType<typeof biomethaneSchema>
type BiomethaneRaw = BiomethaneBase & {
    site: string
    nom_du_projet: string
    capacite_de_production_gwh_an: number
    grx_demandeur: string
    code_reg: string
    code_dep: string
    commune: string
    position: [number, number]
}
export type Installation = BiomethaneBase & {
    filiere: Filiere
    position: [number, number]
}

export const configDb: DbSpec = {
    collections: {
        departements: {
            fetch: fetchJSON.bind(this, DEPARTEMENTS_URL),
            inflate: identity,
            validate: validateRowYup.bind(this, departementSchema, false),
        },

        biomethaneMeta: {
            fetch: fetchJSON.bind(this, BIOMETHANE_META_URL),
            inflate: async (rows) => {
                return { dataDate: rows.dataset.metas.default.modified }
            },
            validate: identity,
        },

        installations: {
            fetch: fetchJSON.bind(this, BIOMETHANE_URL),
            inflate: async (rows) => {
                const biomethaneRaw = await inflateODSExportedData<
                    BiomethaneRaw
                >(rows)
                // We update the objects without doing copies for better performance
                biomethaneRaw.forEach((biomethaneRaw) => {
                    biomethaneRaw.filiere = biomethaneRaw.site as Filiere
                    biomethaneRaw.nominstallation = biomethaneRaw.nom_du_projet
                    biomethaneRaw.puissance =
                        biomethaneRaw.capacite_de_production_gwh_an
                    biomethaneRaw.ville = biomethaneRaw.commune
                    biomethaneRaw.insee_dep = biomethaneRaw.code_dep
                    biomethaneRaw.insee_reg = biomethaneRaw.code_reg
                    biomethaneRaw.reseau = biomethaneRaw.grx_demandeur
                    if (biomethaneRaw.coordonnees) {
                        biomethaneRaw.position = [
                            biomethaneRaw.coordonnees.lon,
                            biomethaneRaw.coordonnees.lat,
                        ]
                    }
                })
                return biomethaneRaw
            },
            validate: validateRowYup.bind(this, biomethaneSchema, true),
        },
    },
}

export default configDb
