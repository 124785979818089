import { ValueType } from 'react-select'
import { useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { useHistory } from 'react-router-dom'
import { selectMapTerritory } from '../../selectors/PageMap'
import {
    selectTerritoryOptions,
    SelectOption,
} from '../../selectors/TerritoryFilters'
import { territoriesAreEqual } from '../../utils/territories'

const defaultFilter = createFilter({
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any', // or 'start'
})

export const useTerritorySelect = () => {
    const history = useHistory()
    const territory = useSelector(selectMapTerritory)
    const options = useSelector(selectTerritoryOptions)
    const onChange = (option: ValueType<SelectOption>) => {
        if (option) {
            history.push((option as SelectOption).value.url!)
        }
    }
    let selectedOption: SelectOption | null = null
    if (territory) {
        selectedOption = options.filter((option) =>
            territoriesAreEqual(option.value.territory, territory)
        )[0]
    }

    const filterOption = (option: SelectOption, query: string) => {
        if (option) {
            if (query.length && option.value.disabled) {
                return false
            }
            return defaultFilter(option as any, query)
        }
        return false
    }

    return { onChange, options, selectedOption, filterOption }
}
