import isEqual from 'lodash.isequal'
import { Installation } from '../config-db'

export type PuissanceBounds = [number, number]

export const installationsAreEqual = (
    installations1: Array<Installation>,
    installations2: Array<Installation>
) => {
    const ids1 = new Set(installations1.map((installation) => installation.id))
    const ids2 = new Set(installations2.map((installation) => installation.id))
    return isEqual(ids1, ids2)
}

export const getPuissanceBounds = (
    installations: Array<Installation>
): PuissanceBounds => {
    let minPuissance = Infinity
    let maxPuissance = -Infinity
    installations.forEach((installation) => {
        minPuissance = Math.min(installation.puissance, minPuissance)
        maxPuissance = Math.max(installation.puissance, maxPuissance)
    })
    return [minPuissance, maxPuissance]
}
