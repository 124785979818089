import { createSelector } from 'reselect'
import {
    makeGeoProjection,
    makeMapPuissanceToRadius,
    generateCanvasDots,
} from '../utils/map'
import {
    mapGroupedResult,
    territoryTypeToGroupedType,
} from '../components/MapsContainer/utils'
import config from '../config'
import { Departement, Installation } from '../config-db'
import { RootState } from '../store'
import {
    GroupedDepartements,
    GroupedInstallations,
    GroupedGeoProjections,
    MapsData,
    GroupedType,
    GroupedResultsGeneric,
    GroupedFlatbushes,
    MapData,
    MouseOverState,
} from '../store/MapsContainer'
import {
    FiliereFilters,
    SPECIAL_DEPARTEMENTS,
    SPECIAL_DEPARTEMENTS_VALUES,
    Territory,
    TerritoryInsee,
    TerritoryType,
} from '../types'
import {
    InstallationsById,
    selectDepartements,
    selectInstallations,
    selectInstallationsById,
} from './core'
import { selectFiliereFilters, selectMapTerritoryFailsafe } from './PageMap'
import { getPuissanceBounds, PuissanceBounds } from '../utils/installations'
import {
    getRegionInseeFromName,
    getDepartementInseeFromName,
} from '../utils/territories'

export const selectMapFlatbushes = (state: RootState) =>
    state.MapsContainer.flatbushes

const selectTerritoryInsee = createSelector(
    selectMapTerritoryFailsafe,
    selectDepartements,
    (territory: Territory, departements: Array<Departement>) => {
        switch (territory.type) {
            case TerritoryType.REGION:
                return {
                    type: territory.type,
                    insee: getRegionInseeFromName(departements, territory.name),
                }
            case TerritoryType.DEPARTEMENT:
                return {
                    type: territory.type,
                    insee: getDepartementInseeFromName(
                        departements,
                        territory.name
                    ),
                }
            default:
                return {
                    type: territory.type,
                    insee: null,
                }
        }
    }
)

export const selectTerritoryDepartements = createSelector(
    selectDepartements,
    selectMapTerritoryFailsafe,
    (
        allDepartements: Array<Departement>,
        territory: Territory
    ): GroupedDepartements => {
        let filtered: Array<Departement> = []
        const groupedType = territoryTypeToGroupedType(territory.type)
        switch (groupedType) {
            case GroupedType.DEPARTEMENT_OR_REGION:
                const filterKey =
                    territory.type === TerritoryType.REGION
                        ? 'nom_reg'
                        : 'nom_dep'
                filtered = allDepartements.filter(
                    (departement) => departement[filterKey] === territory.name
                )
                if (filtered.length === 0) {
                    throw new Error(
                        `No departements found for [ ${filterKey} == ${territory.name} ]`
                    )
                }
                return {
                    type: GroupedType.DEPARTEMENT_OR_REGION,
                    groups: {
                        all: filtered,
                    },
                }

            case GroupedType.PAYS:
                return {
                    type: GroupedType.PAYS,
                    groups: {
                        all: allDepartements,
                        metro: allDepartements.filter(
                            (departement) =>
                                !SPECIAL_DEPARTEMENTS_VALUES.includes(
                                    departement.insee_dep
                                )
                        ),
                        corse: allDepartements.filter(
                            (departement) =>
                                departement.insee_dep ===
                                    SPECIAL_DEPARTEMENTS.CORSE_DU_SUD ||
                                departement.insee_dep ===
                                    SPECIAL_DEPARTEMENTS.HAUTE_CORSE
                        ),
                    },
                }
        }
    }
)

export const selectTerritoryInstallations = createSelector(
    selectInstallations,
    selectTerritoryInsee,
    (
        allInstallations: Array<Installation>,
        territoryInsee: TerritoryInsee
    ): GroupedInstallations => {
        let filtered: Array<Installation> = []
        const groupedType = territoryTypeToGroupedType(territoryInsee.type)
        switch (groupedType) {
            case GroupedType.DEPARTEMENT_OR_REGION:
                const filterKey =
                    territoryInsee.type === TerritoryType.REGION
                        ? 'insee_reg'
                        : 'insee_dep'
                filtered = allInstallations.filter(
                    (installation) =>
                        installation[filterKey] === territoryInsee.insee
                )
                return {
                    type: GroupedType.DEPARTEMENT_OR_REGION,
                    groups: {
                        all: filtered,
                    },
                }

            case GroupedType.PAYS:
                return {
                    type: GroupedType.PAYS,
                    groups: {
                        all: allInstallations,
                        metro: allInstallations.filter(
                            (installation) =>
                                !SPECIAL_DEPARTEMENTS_VALUES.includes(
                                    installation.insee_dep
                                )
                        ),
                        corse: allInstallations.filter(
                            (installation) =>
                                installation.insee_dep ===
                                    SPECIAL_DEPARTEMENTS.CORSE_DU_SUD ||
                                installation.insee_dep ===
                                    SPECIAL_DEPARTEMENTS.HAUTE_CORSE
                        ),
                    },
                }
        }
    }
)

export const selectInstallationsPuissanceBounds = createSelector(
    selectTerritoryInstallations,
    (groupedInstallations: GroupedInstallations) =>
        getPuissanceBounds(groupedInstallations.groups.all)
)

export const selectMapPuissanceToRadius = createSelector(
    selectInstallationsPuissanceBounds,
    selectMapTerritoryFailsafe,
    (puissanceBounds: PuissanceBounds, territory: Territory) =>
        makeMapPuissanceToRadius(territory.type, puissanceBounds)
)

export const selectMapSizes = createSelector(
    selectTerritoryDepartements,
    (groupedDepartements: GroupedDepartements) =>
        mapGroupedResult(groupedDepartements, (_, group) => {
            switch (group) {
                case 'all':
                case 'metro':
                    return config.VISUALIZATION_PIXEL_SIZE
                case 'corse':
                    return config.VISUALIZATION_PIXEL_SIZE
                default:
                    throw new Error(`invalid group ${group}`)
            }
        })
)

export const selectGeoProjections = createSelector(
    selectTerritoryDepartements,
    selectMapSizes,
    (
        groupedDepartements: GroupedDepartements,
        groupedMapSizes: GroupedResultsGeneric<number>
    ): GroupedGeoProjections => {
        return mapGroupedResult(groupedDepartements, (departements, group) =>
            makeGeoProjection(
                group === 'metro' ? 'geoConicConformal' : 'geoMercator',
                groupedDepartements.type === GroupedType.PAYS &&
                    group === 'corse'
                    ? groupedDepartements.groups.metro
                    : departements,
                (groupedMapSizes as any).groups[group],
                (groupedMapSizes as any).groups[group],
                group === 'corse' ? config.MAP_CORSE_OFFSET : undefined
            )
        )
    }
)

// The flatbushes are obtained asynchronously, therefore they would
// trigger re-calculation if we add them at this step.
export const selectMapsDataWithoutFlatbush = createSelector(
    selectTerritoryInstallations,
    selectTerritoryDepartements,
    selectGeoProjections,
    selectMapSizes,
    selectInstallationsPuissanceBounds,
    selectMapPuissanceToRadius,
    (
        groupedInstallations: GroupedInstallations,
        groupedDepartements: GroupedDepartements,
        groupedGeoProjections: GroupedGeoProjections,
        groupedMapSizes: GroupedResultsGeneric<number>,
        puissanceBounds: PuissanceBounds,
        mapPuissanceToRadius: (p: number) => number
    ): GroupedResultsGeneric<Omit<MapData, 'flatbush'>> => {
        console.log('SELECTOR : selectMapsDataWithoutFlatbush')

        return mapGroupedResult(
            groupedInstallations,
            (installations, group) => {
                const geoProjection = (groupedGeoProjections.groups as any)[
                    group
                ]
                const mapSize = (groupedMapSizes.groups as any)[group]
                const {
                    canvasDots,
                    smallCanvasDots,
                    bigCanvasDots,
                } = generateCanvasDots(
                    installations,
                    geoProjection,
                    mapPuissanceToRadius,
                    puissanceBounds
                )

                return {
                    canvasDots,
                    smallCanvasDots,
                    bigCanvasDots,
                    departements: (groupedDepartements.groups as any)[group],
                    geoProjection,
                    mapSize,
                }
            }
        )
    }
)

export const selectMapsData = createSelector(
    selectMapsDataWithoutFlatbush,
    selectMapFlatbushes,
    (
        mapsDataWithoutFlatbush: GroupedResultsGeneric<
            Omit<MapData, 'flatbush'>
        >,
        groupedFlatbushes: GroupedFlatbushes | null
    ): MapsData => {
        return mapGroupedResult(
            mapsDataWithoutFlatbush,
            (mapDataWithoutFlatbush, group) => {
                let flatbush = null
                if (groupedFlatbushes) {
                    flatbush = (groupedFlatbushes.groups as any)[group]
                }
                return {
                    ...mapDataWithoutFlatbush,
                    flatbush,
                }
            }
        )
    }
)

export const selectMouseOver = (state: RootState) =>
    state.MapsContainer.mouseOver

export const selectHighlightedCanvasDot = createSelector(
    selectMouseOver,
    selectInstallationsById,
    selectFiliereFilters,
    (
        mouseOver: MouseOverState,
        installationsById: InstallationsById,
        filiereFilters: FiliereFilters
    ) => {
        if (!mouseOver) {
            return null
        }
        const canvasDots = mouseOver.canvasDots.filter((canvasDot) =>
            filiereFilters.includes(
                installationsById[canvasDot.installationId].filiere
            )
        )
        if (canvasDots.length === 0) {
            return null
        }
        return canvasDots.reduce(
            (biggestCanvasDot, canvasDot) =>
                biggestCanvasDot.radius > canvasDot.radius
                    ? biggestCanvasDot
                    : canvasDot,
            canvasDots[0]
        )
    }
)
