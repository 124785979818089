import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as IconLocalisation } from '../../images/icon-localisation.svg'
import InfoBoxMapEntry from './InfoBoxMapEntry'
import theme from '../../theme'
import { InfoBoxMapData } from '../../selectors/InfoBoxes'

const sectionMixin = `
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
`

const Commune = styled.div`
    ${sectionMixin}
    svg {
        display: inline-block;
        margin-right: 0.3em;
    }
`
const CommuneLabel = styled.span``

const InfoMapBoxEntries = styled.div`
    ${sectionMixin}
    border-bottom: 1px solid ${theme.colors.Grey};
`

const ReseauContainer = styled.div`
    font-size: 85%;
`

export interface Props {
    infoBoxData: InfoBoxMapData
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const InfoBoxMap: React.FunctionComponent<Props> = ({
    infoBoxData,
    className = '',
}) => {
    if (!infoBoxData || !infoBoxData.installation) {
        return null
    }
    const { installation } = infoBoxData

    return (
        <div className={className}>
            <InfoMapBoxEntries>
                <InfoBoxMapEntry installation={installation} />
                <ReseauContainer>
                    Réseau : {installation.reseau}
                </ReseauContainer>
            </InfoMapBoxEntries>
            <Commune>
                <IconLocalisation />
                <CommuneLabel>
                    {installation.ville} ({installation.insee_dep})
                </CommuneLabel>
            </Commune>
        </div>
    )
}

export default styled(React.memo(InfoBoxMap))`
    text-align: center;
    min-width: 13em;
    max-width: 260px;
`
