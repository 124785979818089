import { useEffect, useMemo, useRef, useState } from 'react'
import { Dimensions, FiliereFilters, Position } from '../../types'
import difference from 'lodash.difference'
import intersection from 'lodash.intersection'
import { useDispatch } from 'react-redux'
import debounce from 'lodash.debounce'
import {
    CanvasDot,
    clearMouseOverDots,
    MapData,
    mouseOverDots,
} from '../../store/MapsContainer'
import config from '../../config'
import { flatbushIndexToCanvasDot } from '../../utils/flatbush'
import {
    getPageMousePosition,
    getRelativeMousePosition,
    getRelativeTouchPosition,
    getPageTouchPosition,
    getCurrentTargetDimensions,
} from '../../utils/events'
import { pointerPositionToCanvasPosition } from '../../utils/map'

export const useFiliereFiltersDiff = (
    nextFiliereFilters: FiliereFilters,
    in_: boolean
) => {
    const currentFiliereFiltersRef = useRef<FiliereFilters | null>(null)
    useEffect(() => {
        if (in_ === false && currentFiliereFiltersRef.current === null) {
            return
        }
        currentFiliereFiltersRef.current = nextFiliereFilters
    }, [nextFiliereFilters, in_])

    return useMemo(() => {
        // Case when the map is rendered for the first time, but isn't `in_` yet
        if (in_ === false && currentFiliereFiltersRef.current === null) {
            return {
                filieresIn: [],
                filieresStay: [],
                filieresOut: [],
            }

            // Case when the filters are changed / or map is rendered for the first time
        } else if (in_ === true) {
            return {
                filieresIn: difference(
                    nextFiliereFilters,
                    currentFiliereFiltersRef.current || []
                ) as FiliereFilters,
                filieresOut: difference(
                    currentFiliereFiltersRef.current,
                    nextFiliereFilters
                ) as FiliereFilters,
                filieresStay: intersection(
                    currentFiliereFiltersRef.current,
                    nextFiliereFilters
                ) as FiliereFilters,
            }

            // Case when map has rendered and in_ has switched to false: map is being unmounted
        } else {
            return {
                filieresIn: [],
                filieresStay: [],
                filieresOut: nextFiliereFilters,
            }
        }
    }, [nextFiliereFilters, in_])
}

export const useMapEventHandlers = (
    canvasPixelDimensions: Dimensions,
    mapDatas: Array<MapData>
) => {
    const dispatch = useDispatch()

    // ---------------------- Generic action dispatchers
    const handleCanvasDotsUnderPointer = (
        canvasPixelDimensions: Dimensions,
        canvasScreenDimensions: Dimensions,
        pointerPosition: Position,
        pagePosition: Position
    ) => {
        const [positionX, positionY] = pointerPositionToCanvasPosition(
            canvasPixelDimensions,
            canvasScreenDimensions,
            pointerPosition
        )
        let canvasDots: Array<CanvasDot> = []

        mapDatas.forEach((mapData) => {
            if (!mapData.flatbush) {
                return
            }
            const flatbushIndices = mapData.flatbush.search(
                positionX - config.MAP_OVER_ZONE_PIXELS,
                positionY - config.MAP_OVER_ZONE_PIXELS,
                positionX + config.MAP_OVER_ZONE_PIXELS,
                positionY + config.MAP_OVER_ZONE_PIXELS
            )
            canvasDots = [
                ...canvasDots,
                ...flatbushIndices.map((index) =>
                    flatbushIndexToCanvasDot(index, mapData)
                ),
            ]
        })
        dispatch(mouseOverDots(pagePosition, canvasDots))
    }

    const handleClear = () => {
        dispatch(clearMouseOverDots())
    }

    // ---------------------- Debouncing pointer move
    const debouncedPointerMove = debounce(
        handleCanvasDotsUnderPointer,
        config.INFOBOX_DEBOUNCE_TIME
    )

    // Cancel debounce when unmounting the page
    useEffect(() => {
        return () => {
            debouncedPointerMove.cancel()
            disarmTouchStartTimeout()
        }
    }, [debouncedPointerMove])

    // ---------------------- TOUCH events
    const [touchMapActive, setTouchMapActive] = useState(false)
    // We make this a mutable to avoid re-rendering everytime we reset the timer
    const timeoutHandleRef = useRef<number | null>(null)

    const armTouchStartTimeout = (
        canvasPixelDimensions: Dimensions,
        canvasScreenDimensions: Dimensions,
        position: Position,
        pagePosition: Position
    ) => {
        if (timeoutHandleRef.current !== null) {
            clearTimeout(timeoutHandleRef.current)
        }
        timeoutHandleRef.current = setTimeout(() => {
            console.log('touch map')
            handleCanvasDotsUnderPointer(
                canvasPixelDimensions,
                canvasScreenDimensions,
                position,
                pagePosition
            )
            setTouchMapActive(true)
        }, config.INFOBOX_DEBOUNCE_TIME)
        setTouchMapActive(false)
    }

    const disarmTouchStartTimeout = () => {
        if (timeoutHandleRef.current !== null) {
            clearTimeout(timeoutHandleRef.current)
        }
    }

    return {
        eventHandlers: {
            // Ref : https://medium.com/@anuhosad/debouncing-events-with-react-b8c405c33273
            onMouseMove: (event: React.MouseEvent) => {
                debouncedPointerMove(
                    canvasPixelDimensions,
                    getCurrentTargetDimensions(event),
                    getRelativeMousePosition(event),
                    getPageMousePosition(event)
                )
            },
            onMouseOut: () => {
                debouncedPointerMove.cancel()
                handleClear()
            },

            onTouchStart: (event: React.TouchEvent) => {
                armTouchStartTimeout(
                    canvasPixelDimensions,
                    getCurrentTargetDimensions(event),
                    getRelativeTouchPosition(event),
                    getPageTouchPosition(event)
                )
            },
            onTouchMove: (event: React.TouchEvent) => {
                const position = getRelativeTouchPosition(event)
                const pagePosition = getPageTouchPosition(event)
                if (touchMapActive) {
                    debouncedPointerMove(
                        canvasPixelDimensions,
                        getCurrentTargetDimensions(event),
                        position,
                        pagePosition
                    )
                } else {
                    armTouchStartTimeout(
                        canvasPixelDimensions,
                        getCurrentTargetDimensions(event),
                        position,
                        pagePosition
                    )
                }
            },
            onTouchEnd: () => {
                debouncedPointerMove.cancel()
                disarmTouchStartTimeout()
                handleClear()
                setTouchMapActive(false)
            },
        },
        touchMapActive,
    }
}
