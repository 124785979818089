import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { buildTransitionHelper } from '../core/animations/helpers'
import logoSrc from '../images/logo-ORE-white-@2x.png'
import theme from '../theme'

const CSS_TRANSITION_CLASSNAMES = 'HeaderMobile'

const Logo = styled.a`
    display: block;
    height: 100%;
    img {
        height: 100%;
    }
`

const Title = styled.h1`
    color: ${theme.colors.White};
    font-size: 130%;
    line-height: 1.25rem;
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const HeaderMobile: React.FunctionComponent<Props> = ({ className = '' }) => {
    return (
        <CSSTransition
            classNames={CSS_TRANSITION_CLASSNAMES}
            in={true}
            timeout={theme.transitions.enterDuration}
            appear
        >
            <div className={className}>
                <Logo href="https://www.agenceore.fr/" target="blank_">
                    <img src={logoSrc} alt="logo ORE" />
                </Logo>
                <Title>
                    Répartition des installations de production de biométhane
                </Title>
            </div>
        </CSSTransition>
    )
}

const { all } = theme.transitions.pageEnterSequenceMobile.Header
const enterTransition = buildTransitionHelper.enterOrAppear(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(HeaderMobile))`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${theme.dimensions.headerMobileHeight};
    z-index: ${theme.zIndexes.footerHeader};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: ${theme.spacings.horizontal1};
    ${theme.mixins.mobileGradient}

    ${enterTransition(
        null,
        [['transform', 'translateY(-100%)', 'translateY(0%)']],
        all[0],
        all[1]
    )}
`
