import sortBy from 'lodash.sortby'
import { useSelector } from 'react-redux'
import { FilieresConfig } from '../../config'
import { selectEnabledFilieres } from '../../selectors/FiliereFilters'
import { selectFiliereFilters } from '../../selectors/PageMap'
import { RootState } from '../../store'
import { Filiere } from '../../types'

export const useFiliereFilters = (isDataReadyToRender: boolean) => {
    const filieresShowing = useSelector(selectFiliereFilters)
    const filieresEnabled = useSelector((state: RootState) => {
        if (isDataReadyToRender) {
            return selectEnabledFilieres(state)
        }
        return []
    })
    const filieres = sortBy(
        Object.keys(FilieresConfig) as Array<Filiere>,
        (filiere) => filiere
    )
    return { filieres, filieresShowing, filieresEnabled }
}
