import { GeoProjection } from 'd3-geo'
import { Flatbush } from 'flatbush'
import { Departement, Installation } from '../config-db'
import { Territory, Position } from '../types'
import { PageMapTypes } from './PageMap'

// ---------- Types definitions ---------- //
export interface CanvasDot {
    installationId: string
    position: [number, number]
    radius: number
    color: string
    puissance: number
}

export type CanvasDotsIndexedByFiliere = { [filiere: string]: Array<CanvasDot> }

export interface MapData {
    canvasDots: Array<CanvasDot>
    smallCanvasDots: CanvasDotsIndexedByFiliere
    bigCanvasDots: CanvasDotsIndexedByFiliere
    departements: Array<Departement>
    geoProjection: GeoProjection
    flatbush: Flatbush | null
    mapSize: number
}

export enum GroupedType {
    DEPARTEMENT_OR_REGION,
    PAYS,
}

export interface GroupedResultsPays<T> {
    type: GroupedType.PAYS
    groups: {
        all: T
        metro: T
        corse: T
    }
}

export interface GroupedResultsDepartementOrRegion<T> {
    type: GroupedType.DEPARTEMENT_OR_REGION
    groups: {
        all: T
    }
}

export type GroupedResultsGeneric<T> =
    | GroupedResultsPays<T>
    | GroupedResultsDepartementOrRegion<T>

export type GroupedDepartements = GroupedResultsGeneric<Array<Departement>>
export type GroupedInstallations = GroupedResultsGeneric<Array<Installation>>
export type GroupedGeoProjections = GroupedResultsGeneric<GeoProjection>
export type GroupedFlatbushes = GroupedResultsGeneric<Flatbush | null>
export type MapsData =
    | GroupedResultsPays<MapData>
    | GroupedResultsDepartementOrRegion<MapData>

export interface IndexInstallationsWorkerRequest {
    mapsData: MapsData
    territory: Territory
}

// ------------- Action Types ------------ //
export const MAPS_CONTAINER_SET_INSTALLATIONS_FLATBUSHES =
    'MAPS_CONTAINER_SET_INSTALLATIONS_FLATBUSHES'
export const MAPS_CONTAINER_MOUSE_OVER_DOTS = 'MAPS_CONTAINER_MOUSE_OVER_DOTS'

interface SetInstallationFlatbushes {
    type: typeof MAPS_CONTAINER_SET_INSTALLATIONS_FLATBUSHES
    payload: GroupedFlatbushes
}

interface SetMouseOverDots {
    type: typeof MAPS_CONTAINER_MOUSE_OVER_DOTS
    payload: {
        pageMousePosition: Position
        canvasDots: Array<CanvasDot>
    } | null
}

export type MapsContainerType = SetInstallationFlatbushes | SetMouseOverDots

// ------------ Action Creators ---------- //
export const mouseOverDots = (
    pageMousePosition: Position,
    canvasDots: Array<CanvasDot>
): MapsContainerType => {
    return {
        type: MAPS_CONTAINER_MOUSE_OVER_DOTS,
        payload: {
            pageMousePosition,
            canvasDots,
        },
    }
}

export const clearMouseOverDots = (): MapsContainerType => {
    return {
        type: MAPS_CONTAINER_MOUSE_OVER_DOTS,
        payload: null,
    }
}

export const setInstallationFlatbushes = (
    installationFlatbushes: GroupedFlatbushes
) => {
    return {
        type: MAPS_CONTAINER_SET_INSTALLATIONS_FLATBUSHES,
        payload: installationFlatbushes,
    }
}

// ----------------- State --------------- //
export type MouseOverState = {
    canvasDots: Array<CanvasDot>
    pageMousePosition: Position
} | null

export interface MapsContainerState {
    flatbushes: GroupedFlatbushes | null
    mouseOver: MouseOverState
}

const initialState: MapsContainerState = {
    flatbushes: null,
    mouseOver: null,
}

// ---------------- Reducer -------------- //
export function mapsContainerReducer(
    state = initialState,
    action: MapsContainerType | PageMapTypes
): MapsContainerState {
    switch (action.type) {
        case MAPS_CONTAINER_SET_INSTALLATIONS_FLATBUSHES:
            return {
                ...state,
                flatbushes: action.payload,
            }
        case MAPS_CONTAINER_MOUSE_OVER_DOTS:
            return {
                ...state,
                mouseOver: action.payload,
            }
        default:
            return state
    }
}
