import { Filiere, FiliereFilters, Position, Territory } from '../types'
import difference from 'lodash.difference'
import isEqual from 'lodash.isequal'
import { FilieresConfig } from '../config'

// ------------- Action Types ------------ //
export const PAGE_MAP_SET_TERRITORY = 'PAGE_MAP_SET_TERRITORY'
export const PAGE_MAP_SET_VISUALIZATION_WIDTH =
    'PAGE_MAP_SET_VISUALIZATION_WIDTH'
export const PAGE_MAP_INITIALIZE = 'PAGE_MAP_INITIALIZE'
export const PAGE_MAP_FILIERE_FILTER_TOGGLE = 'PAGE_MAP_FILIERE_FILTER_TOGGLE'
export const PAGE_MAP_SECTION_INFO_OVER = 'PAGE_MAP_SECTION_INFO_OVER'

export interface SetTerritory {
    type: typeof PAGE_MAP_SET_TERRITORY
    payload: Territory
}

interface SetVisualizationWidth {
    type: typeof PAGE_MAP_SET_VISUALIZATION_WIDTH
    payload: number
}

interface InitializePageMap {
    type: typeof PAGE_MAP_INITIALIZE
    payload: {
        territory: Territory
        visualizationWidth: number
    }
}

interface SetFiliereFilter {
    type: typeof PAGE_MAP_FILIERE_FILTER_TOGGLE
    payload: {
        filiere: Filiere
        isShowing: boolean
    }
}

interface SetSectionInfoOver {
    type: typeof PAGE_MAP_SECTION_INFO_OVER
    payload: MouseOverSectionInfoState
}

export type PageMapTypes =
    | SetTerritory
    | SetVisualizationWidth
    | InitializePageMap
    | SetFiliereFilter
    | SetSectionInfoOver

// ------------ Action Creators ---------- //
export const setTerritory = (territory: Territory): PageMapTypes => {
    return {
        type: PAGE_MAP_SET_TERRITORY,
        payload: territory,
    }
}

export const setVisualizationWidth = (
    visualizationWidth: number
): PageMapTypes => {
    return {
        type: PAGE_MAP_SET_VISUALIZATION_WIDTH,
        payload: visualizationWidth,
    }
}

export const initializePageMapState = (
    territory: Territory,
    visualizationWidth: number
): PageMapTypes => {
    return {
        type: PAGE_MAP_INITIALIZE,
        payload: { territory, visualizationWidth },
    }
}

export const toggleFiliereFilter = (
    filiere: Filiere,
    isShowing: boolean
): PageMapTypes => {
    return {
        type: PAGE_MAP_FILIERE_FILTER_TOGGLE,
        payload: {
            filiere,
            isShowing,
        },
    }
}

export const setMouseOverSectionInfo = (
    pageMousePosition: Position,
    text: string
): PageMapTypes => {
    return {
        type: PAGE_MAP_SECTION_INFO_OVER,
        payload: { pageMousePosition, text },
    }
}

export const clearMouseOverSectionInfo = (): PageMapTypes => {
    return {
        type: PAGE_MAP_SECTION_INFO_OVER,
        payload: null,
    }
}

// ----------------- State --------------- //
const getInitialFiliereFilters = () =>
    Object.keys(FilieresConfig) as FiliereFilters

export type MouseOverSectionInfoState = {
    text: string
    pageMousePosition: Position
} | null

type PageMapState = {
    initialized: boolean
    territory: Territory | null
    visualizationWidth: number
    filiereFilters: FiliereFilters
    mouseOverSectionInfo: MouseOverSectionInfoState
}

const initialState: PageMapState = {
    initialized: false,
    territory: null,
    visualizationWidth: 0,
    filiereFilters: getInitialFiliereFilters(),
    mouseOverSectionInfo: null,
}

// ---------------- Reducer -------------- //
export function pageMapReducer(
    state = initialState,
    action: PageMapTypes
): PageMapState {
    switch (action.type) {
        case PAGE_MAP_INITIALIZE:
            return {
                ...state,
                ...action.payload,
                initialized: true,
            }
        case PAGE_MAP_SET_TERRITORY:
            return {
                ...state,
                territory: action.payload,
            }
        case PAGE_MAP_SET_VISUALIZATION_WIDTH:
            return {
                ...state,
                visualizationWidth: action.payload,
            }
        case PAGE_MAP_SECTION_INFO_OVER:
            return {
                ...state,
                mouseOverSectionInfo: action.payload,
            }
        case PAGE_MAP_FILIERE_FILTER_TOGGLE:
            let newFiliereFilters: FiliereFilters = []

            // When click on one filiere, if all are active it deactivates all but that one
            if (
                state.filiereFilters.length ===
                Object.keys(FilieresConfig).length
            ) {
                newFiliereFilters = [action.payload.filiere]

                // When only one is active, and we click on it, it reactivates all the others
            } else if (
                isEqual(state.filiereFilters, [action.payload.filiere])
            ) {
                newFiliereFilters = getInitialFiliereFilters()
            } else if (state.filiereFilters.includes(action.payload.filiere)) {
                newFiliereFilters = difference(state.filiereFilters, [
                    action.payload.filiere,
                ])
            } else {
                newFiliereFilters = [
                    ...state.filiereFilters,
                    action.payload.filiere,
                ]
            }
            return {
                ...state,
                filiereFilters: newFiliereFilters,
            }
        default:
            return state
    }
}
