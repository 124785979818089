import {
    geoMercator,
    geoConicConformal,
    geoBounds,
    geoCentroid,
    geoDistance,
    GeoProjection,
} from 'd3-geo'
import { scaleLinear } from 'd3-scale'
import { Departement, Installation } from '../config-db'
import { GeometryCollection } from 'geojson'
import { Dimensions, Filiere, Position, TerritoryType } from '../types'
import { CanvasDot, CanvasDotsIndexedByFiliere } from '../store/MapsContainer'
import { PuissanceBounds } from './installations'
import { guard } from '../core/typescript'
import config, { FilieresConfig } from '../config'

type PuissanceToRadiusMapping = (puissance: number) => number

export const combineToGeometryCollection = (
    departements: Array<Departement>
) => {
    const geometryCollection: GeometryCollection = {
        type: 'GeometryCollection',
        geometries: [],
    }
    departements.forEach((departement) => {
        departement.geo_shape.geometries.forEach((geometry) =>
            geometryCollection.geometries.push(geometry)
        )
    })
    return geometryCollection
}

export const makeMapPuissanceToRadius = (
    territoryType: TerritoryType,
    [minPuissance, maxPuissance]: PuissanceBounds
): PuissanceToRadiusMapping => {
    const [widthRatioMin, widthRatioMax] = config.PUISSANCE_MAPPING_WIDTH_RATIO[
        territoryType
    ]
    const puissanceToCircleSurface = scaleLinear()
        .domain([minPuissance, maxPuissance])
        .range([
            Math.PI *
                Math.pow(widthRatioMin * config.VISUALIZATION_PIXEL_SIZE, 2),
            Math.PI *
                Math.pow(widthRatioMax * config.VISUALIZATION_PIXEL_SIZE, 2),
        ])

    return (puissance: number) =>
        Math.pow(puissanceToCircleSurface(puissance) / Math.PI, 0.5)
}

export const makeGeoProjection = (
    type: 'geoConicConformal' | 'geoMercator',
    departements: Array<Departement>,
    canvasWidth: number,
    canvasHeight: number,
    offset?: [number, number]
) => {
    const topology = combineToGeometryCollection(departements)
    const bounds = geoBounds(topology)
    const center = geoCentroid(topology)
    if (offset) {
        center[0] += offset[0]
        center[1] += offset[1]
    }
    // Compute the angular distance between bound corners
    const distance = geoDistance(bounds[0], bounds[1])

    if (type === 'geoMercator') {
        return geoMercator()
            .translate([canvasWidth / 2, canvasHeight / 2])
            .scale(canvasHeight / distance / Math.sqrt(2))
            .center(center)
    } else if (type === 'geoConicConformal') {
        return geoConicConformal()
            .translate([canvasWidth / 2, canvasHeight / 2])
            .scale(canvasHeight / distance)
            .center(center)
    } else {
        throw new Error(`invalid geo projection : ${type}`)
    }
}

const installationToCanvasDot = (
    installation: Installation,
    geoProjection: GeoProjection,
    mapPuissanceToRadius: (puissance: number) => number
): CanvasDot => {
    const position = guard(geoProjection(installation.position))
    const radius = mapPuissanceToRadius(installation.puissance)
    return {
        installationId: installation.id,
        puissance: installation.puissance,
        position,
        radius,
        color: FilieresConfig[installation.filiere].color,
    }
}

export const generateCanvasDots = (
    installations: Array<Installation>,
    geoProjection: GeoProjection,
    mapPuissanceToRadius: PuissanceToRadiusMapping,
    puissanceBounds: PuissanceBounds
) => {
    const scaleInstallationPuissance = scaleLinear()
        .domain(puissanceBounds)
        .range([0, 1])
    const canvasDots: Array<CanvasDot> = []
    const bigCanvasDots: CanvasDotsIndexedByFiliere = {}
    const smallCanvasDots: CanvasDotsIndexedByFiliere = {}
    Object.keys(FilieresConfig).forEach((filiere) => {
        bigCanvasDots[filiere] = []
        smallCanvasDots[filiere] = []
    })

    installations.forEach((installation) => {
        const canvasDot = installationToCanvasDot(
            installation,
            geoProjection,
            mapPuissanceToRadius
        )
        canvasDots.push(canvasDot)
        ;(scaleInstallationPuissance(canvasDot.puissance) >
        config.BIG_INSTALLATION_PUISSANCE_THRESHOLD
            ? bigCanvasDots
            : smallCanvasDots)[installation.filiere].push(canvasDot)
    })

    return { canvasDots, bigCanvasDots, smallCanvasDots }
}

export const filterCanvasDotsByFilieres = (
    canvasDotsByFiliere: CanvasDotsIndexedByFiliere,
    filiereFilters: Array<Filiere>
) => {
    const filteredCanvasDots: CanvasDotsIndexedByFiliere = {}
    Object.entries(canvasDotsByFiliere).forEach(([filiere, canvasDots]) => {
        if (filiereFilters.includes(filiere as Filiere)) {
            filteredCanvasDots[filiere] = canvasDots
        }
    })
    return filteredCanvasDots
}

export const pointerPositionToCanvasPosition = (
    { width: canvasPixelWidth, height: canvasPixelHeight }: Dimensions,
    { width: canvasScreenWidth, height: canvasScreenHeight }: Dimensions,
    [pointerX, pointerY]: Position
): Position => [
    (pointerX / canvasScreenWidth) * canvasPixelWidth,
    (pointerY / canvasScreenHeight) * canvasPixelHeight,
]
