const colors = {
    Black: '#202144',
    White: '#FFFFFF',
    GreyLight: '#E9E9EC',
    Grey150: '#BFBB96',
    Grey: '#CCC9B4',
    GreyPer40: '#E5E4D5',
    Grey50: '#EEECDC',
    Grey50Per50: '#F6F5ED',
    Green: '#00B4A0',
    GreenLinks: '#53CCBF',
    DarkBlue: '#141446',
    DarkBlueDarker: '#33335F',
}

const dimensions = {
    thresholdMobile: 768,
    thresholdBigScreen: 1280,
    thresholdVeryBigScreen: 1440,
    bigScreenMargin: 280 / 2,
    bigScreenMaxWidth: 1197,
    borderRadius2: '2rem',
    borderRadius1: '1rem',
    borderRadius05: '0.4rem',
    footerMobileSelectHeight: 45,
    footerMobileHeight: 80,
    headerMobileHeight: '7rem',
    mobileTreeMapHeight: 0.6, // ratio of window height
}

const mixins = {
    appMarginsMixin: `
        max-width: ${
            dimensions.thresholdBigScreen - dimensions.bigScreenMargin * 2
        }px;
        margin: auto;
        @media (min-width: ${dimensions.thresholdBigScreen}px) {
            padding: 0;
            max-width: initial;
            width: calc(100vw - ${dimensions.bigScreenMargin}px * 2);
        }
        @media (min-width: ${dimensions.thresholdVeryBigScreen}px) {
            max-width: ${dimensions.bigScreenMaxWidth}px;
            width: 100%;
        }
    `,
    mobileGradient: `
        background: rgb(0,179,159);
        background: linear-gradient(180deg, rgba(0,179,159,1) 0%, rgba(19,26,73,1) 74%);
    `,
    resetReactSelectMixin: `
        .ReactSelect__control {
            cursor: pointer;
            border-radius: ${dimensions.borderRadius2};
            box-shadow: none;
            .ReactSelect__indicator-separator {
                display: none;
            }
            &.ReactSelect__control--is-focused {
                border: none;
            }
        }
    `,
    reactSelectMobileMixin: `
        .ReactSelect__indicator {
            svg * {
                fill ${colors.Black};
            }
        }
        .ReactSelect__control {
            &.ReactSelect__control--menu-is-open {
                .ReactSelect__dropdown-indicator {
                    transform: rotate(180deg);
                }
            }
        }
    `,
}

const transitions = {
    enterDuration: 3000,
    exitDuration: 500,
    mapsEnterDuration: 2000,
    mapsExitDuration: 500,
    infoboxEnterDuration: 200,
    infoboxExitDuration: 200,
    shortDuration: 200,
    modalDuration: 500,
    pageEnterSequence: {
        // Section 1
        Header: {
            gradient: [0, 0.2],
            logoAndText: [0.1, 0.2],
        },
        // Section 2
        TerritoryFiltersDesktop: {
            title: [0.2, 0.2],
            select: [0.2, 0.4],
        },
        FiliereFiltersDesktop: {
            title: [0.2, 0.2],
            capsules: [0.2, 0.5],
        },
        // Section 3
        MapsContainer: {
            title: [0.6, 0.2],
            visualization: [0.6, 0.4],
        },
        TreeMap: {
            title: [0.6, 0.2],
            visualization: [0.6, 0.4],
        },
    },
    pageEnterSequenceMobile: {
        // Section 1
        Header: {
            all: [0, 0.2],
        },
        Footer: {
            all: [0, 0.2],
        },
        // Section 2
        MapsContainer: {
            title: [0.3, 0.2],
            visualization: [0.3, 0.4],
        },
        // Section 3
        TreeMap: {
            title: [0.5, 0.2],
            visualization: [0.5, 0.4],
        },
    },
}

export default {
    colors: {
        ...colors,
        GreyBorder: colors.Grey150,
    },
    mixins,
    fonts: {
        default: "'Open Sans', sans-serif",
        // default: "'Roboto', sans-serif",
    },
    dimensions,
    spacings: {
        vertical05: '0.5rem',
        horizontal05: '0.5rem',
        horizontal1: '1rem',
        vertical1: '1rem',
        vertical15: '1.5rem',
        vertical2: '2rem',
        vertical3: '3rem',
    },
    fontSizes: {
        baseDesktop: 14,
        baseMobile: 12,
    },
    transitions,
    maps: {
        colorDepartements: colors.Grey50,
        colorBackground: colors.White,
    },
    zIndexes: {
        mapControls: 3,
        infoBoxes: 7,
        selectMenu: 9,
        footerHeader: 10,
        modalOverlay: 13,
        modal: 15,
    },
}
