import { Departement, Installation, BiomethaneMeta } from '../config-db'
import { RequestStatus } from '../types'

// ------------- Action Types ------------ //
export const RAW_DATA_LOAD = 'RAW_DATA_LOAD'
export const RAW_DATA_LOAD_SUCCESS = 'RAW_DATA_LOAD_SUCCESS'
export const RAW_DATA_LOAD_ERROR = 'RAW_DATA_LOAD_ERROR'

export interface RawData {
    departements: Array<Departement>
    installations: Array<Installation>
    biomethaneMeta: BiomethaneMeta
}

export type RawDataErrors = Array<[string, Error]>

interface RawDataLoad {
    type: typeof RAW_DATA_LOAD
}

interface RawDataLoadSuccess {
    type: typeof RAW_DATA_LOAD_SUCCESS
    payload: RawData
}

interface RawDataLoadError {
    type: typeof RAW_DATA_LOAD_ERROR
    payload: RawDataErrors
}

export type RawDataTypes = RawDataLoad | RawDataLoadSuccess | RawDataLoadError

// ------------ Action Creators ---------- //
export const rawDataLoad = (): RawDataTypes => {
    return {
        type: RAW_DATA_LOAD,
    }
}

export const rawDataLoadSuccess = (data: RawData): RawDataTypes => {
    return {
        type: RAW_DATA_LOAD_SUCCESS,
        payload: data,
    }
}

export const rawDataLoadError = (errors: RawDataErrors): RawDataTypes => {
    return {
        type: RAW_DATA_LOAD_ERROR,
        payload: errors,
    }
}

// ----------------- State --------------- //
export interface RawDataState {
    data: RawData | null
    status: RequestStatus
    errors: RawDataErrors
}

const initialState: RawDataState = {
    data: null,
    status: RequestStatus.INIT,
    errors: [],
}

// ---------------- Reducer -------------- //
export function rawDataReducer(
    state = initialState,
    action: RawDataTypes
): RawDataState {
    switch (action.type) {
        case RAW_DATA_LOAD:
            return {
                ...state,
                status: RequestStatus.PROGRESS,
                data: null,
                errors: [],
            }
        case RAW_DATA_LOAD_SUCCESS:
            return {
                ...state,
                status: RequestStatus.DONE,
                data: action.payload,
            }
        case RAW_DATA_LOAD_ERROR:
            return {
                ...state,
                status: RequestStatus.DONE,
                errors: action.payload,
            }
        default:
            return state
    }
}
