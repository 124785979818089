import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { D3HierarchyData } from '../../utils/treemap'
import gradientSrc from '../../images/treemap-gradient-@2x.png'
import { formatNumber } from '../../utils/format'
import theme from '../../theme'
import { useDispatch } from 'react-redux'
import { getPageMousePosition } from '../../utils/events'
import { wrapText } from '../../core/strings'
import { clearMouseOverRect, mouseOverRect } from '../../store/TreeMap'
import { FilieresConfig } from '../../config'

const TEXT_H_PADDING = 13
const TEXT_TOP_PADDING = '1.5em'

const RectangleText = styled.text`
    font-size: 200%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 300%;
    }
`

const Percentage = styled.tspan`
    font-weight: bold;
    font-size: 110%;
`

const GradientImage = styled.image`
    /* To avoid mouseOut from being triggered when mouse over the image */
    pointer-events: none;
`

export interface Props {
    x0: number
    y0: number
    x1: number
    y1: number
    data: any
    className?: string
}

const Component: React.FunctionComponent<Props> = ({
    x0,
    y0,
    x1,
    y1,
    data,
    className,
}) => {
    const dispatch = useDispatch()
    const { filiere, ratio } = data as D3HierarchyData
    const textRef = useRef<SVGTextElement | null>(null)
    const rectRef = useRef<SVGRectElement | null>(null)
    const [isTextHidden, setIsTextHidden] = useState(false)
    const [wrappedText, setWrappedText] = useState<Array<string> | null>(null)
    const transform = `translate(${x0} ${y0})`
    const svgWidth = x1 - x0
    const svgHeight = y1 - y0
    const gradientSize = Math.min(svgWidth, svgHeight)
    const gradientX = svgWidth - gradientSize
    const gradientY = svgHeight - gradientSize
    const percentageDisplay =
        ratio * 100 >= 0.1 ? `${formatNumber(ratio * 100, 1)}%` : '< 0,1%'

    const filiereDisplay = FilieresConfig[filiere].display
    const filiereLines = wrappedText ? wrappedText : [filiereDisplay]

    useEffect(() => {
        if (!textRef.current || !rectRef.current) {
            return
        }
        // We can't use width / height as they are in the SVG coordinates
        const {
            width: textWidth,
            height: textHeight,
            x: textX,
            y: textY,
        } = textRef.current.getBoundingClientRect()
        const {
            width: rectWidth,
            height: rectHeight,
            x: rectX,
            y: rectY,
        } = rectRef.current.getBoundingClientRect()
        if (
            textX + textWidth + TEXT_H_PADDING > rectX + rectWidth ||
            textY + textHeight > rectY + rectHeight
        ) {
            // We first try wrapping text, then render again
            // and if it's still too big, we simply hide the text.
            if (wrappedText === null) {
                setWrappedText(wrapText(filiereDisplay, 2))
            } else {
                setIsTextHidden(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrappedText])

    const mouseOver = (event: React.MouseEvent) =>
        dispatch(
            mouseOverRect(
                getPageMousePosition(event),
                filiere,
                percentageDisplay,
                isTextHidden
            )
        )

    const mouseOut = () => dispatch(clearMouseOverRect())

    return (
        <g
            transform={transform}
            key={filiere}
            onMouseMove={mouseOver}
            onMouseOut={mouseOut}
            onTouchEnd={mouseOut}
            className={className}
        >
            <rect
                width={svgWidth}
                height={svgHeight}
                fill={FilieresConfig[filiere].color}
                ref={rectRef}
            />
            <GradientImage
                href={gradientSrc}
                x={gradientX}
                y={gradientY}
                width={gradientSize}
                height={gradientSize}
            />
            {!isTextHidden ? (
                <RectangleText
                    ref={textRef}
                    fill={FilieresConfig[filiere].colorText}
                >
                    {filiereLines.map((filiereLine, i) => (
                        <tspan
                            key={i}
                            x={`${TEXT_H_PADDING}px`}
                            {...(i === 0
                                ? { y: TEXT_TOP_PADDING }
                                : { dy: '1.25em' })}
                        >
                            {filiereLine}
                        </tspan>
                    ))}
                    <Percentage dy="1.25em" x={`${TEXT_H_PADDING}px`}>
                        {percentageDisplay}
                    </Percentage>
                </RectangleText>
            ) : null}
        </g>
    )
}

export default styled(React.memo(Component))`
    user-select: none;
`
