import config from '../config'
import { createSelector } from 'reselect'
import { RootState } from '../store'
import theme from '../theme'
import { Dimensions, FiliereFilters, Territory } from '../types'
import { selectWindowDimensions } from './core'

export const selectPageMapInitialized = (state: RootState) =>
    state.PageMap.initialized

export const selectMapTerritory = (state: RootState) => state.PageMap.territory

export const selectMapTerritoryFailsafe = (state: RootState) => {
    const territory = selectMapTerritory(state)
    if (!territory) {
        throw new Error('Expected territory to be non-null')
    }
    return territory
}

export const selectFiliereFilters = (state: RootState): FiliereFilters =>
    state.PageMap.filiereFilters

// Visualization height actually depends on the height of the MapsContainer
export const selectVisualizationDimensions = createSelector(
    selectWindowDimensions,
    selectMapTerritory,
    (windowDimensions: Dimensions, territory: Territory | null) => {
        if (territory === null) {
            return {
                width: 0,
                treeMapHeight: 0,
                mapHeight: 0,
            }
        }
        const isMobile =
            windowDimensions.width < theme.dimensions.thresholdMobile
        const mobileTreeMapHeight =
            config.VISUALIZATION_PIXEL_SIZE *
            (windowDimensions.height / windowDimensions.width) *
            theme.dimensions.mobileTreeMapHeight

        return {
            width: config.VISUALIZATION_PIXEL_SIZE,
            mapHeight: config.VISUALIZATION_PIXEL_SIZE,
            treeMapHeight: isMobile
                ? mobileTreeMapHeight
                : config.VISUALIZATION_PIXEL_SIZE,
        }
    }
)

export const selectMouseOverSectionInfo = (state: RootState) =>
    state.PageMap.mouseOverSectionInfo
