import React, { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { useSyncPageMapState } from './hooks'
import MapsContainer from '../MapsContainer'
import { useDispatch, useSelector } from 'react-redux'
import { selectWindowDimensions } from '../../selectors/core'
import FiliereFiltersDesktop from '../FiliereFilters/FiliereFiltersDesktop'
import InfoBoxes from '../InfoBoxes'
import TreeMap from '../TreeMap'
import HeaderDesktop from '../HeaderDesktop'
import FooterInfos from '../FooterInfos'
import TerritoryFiltersDesktop from '../TerritoryFilters/TerritoryFiltersDesktop'
import HeaderMobile from '../HeaderMobile'
import FooterMobile from '../FooterMobile'
import { clearMouseOverDots } from '../../store/MapsContainer'
import { clearMouseOverRect } from '../../store/TreeMap'
import { clearMouseOverSectionInfo } from '../../store/PageMap'

const MainContainer = styled.div`
    ${theme.mixins.appMarginsMixin}
    padding: 0 ${theme.spacings.horizontal1};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        padding: 0 ${theme.spacings.horizontal05};
    }
`

const VisualizationsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > * {
        width: calc(50% - ${theme.spacings.horizontal05});
    }
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: block;
        > * {
            width: 100%;
        }
    }
`

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    > *:first-child {
        width: 30%;
        margin-right: ${theme.spacings.horizontal1};
    }
    > *:last-child {
        width: 70%;
    }
`

const MapsContainerMeasureSize = styled.div``

export interface Props {
    className?: string
}

const PageMap: React.FunctionComponent<Props> = ({ className = '' }) => {
    const dispatch = useDispatch()
    const [pageScrollIsAtBottom, setPageScrollIsAtBottom] = useState(false)
    const mapContainerRef = useRef<HTMLDivElement | null>(null)
    const isDataReadyToRender = useSyncPageMapState(mapContainerRef)
    const { width: windowWidth, height: windowHeight } = useSelector(
        selectWindowDimensions
    )
    const isMobile = windowWidth < theme.dimensions.thresholdMobile

    const onScroll = (event: React.SyntheticEvent) => {
        setPageScrollIsAtBottom(false)
        if (
            event.currentTarget.scrollTop + windowHeight ===
            event.currentTarget.scrollHeight
        ) {
            setPageScrollIsAtBottom(true)
        }
        dispatch(clearMouseOverDots())
        dispatch(clearMouseOverRect())
        dispatch(clearMouseOverSectionInfo())
    }

    return (
        <div className={className} onScroll={onScroll}>
            {isMobile ? <HeaderMobile /> : <HeaderDesktop />}
            <MainContainer>
                {!isMobile ? (
                    <FiltersContainer>
                        <TerritoryFiltersDesktop />
                        <FiliereFiltersDesktop
                            isDataReadyToRender={isDataReadyToRender}
                        />
                    </FiltersContainer>
                ) : null}
                <VisualizationsContainer>
                    <MapsContainerMeasureSize ref={mapContainerRef}>
                        {isDataReadyToRender ? <MapsContainer /> : null}
                    </MapsContainerMeasureSize>
                    {isDataReadyToRender ? <TreeMap /> : null}
                </VisualizationsContainer>
            </MainContainer>
            <FooterInfos />
            {isMobile ? (
                <FooterMobile
                    pageScrollIsAtBottom={pageScrollIsAtBottom}
                    isDataReadyToRender={isDataReadyToRender}
                />
            ) : null}
            <InfoBoxes />
        </div>
    )
}

export default styled(React.memo(PageMap))`
    /* We set this to capture the scroll events */
    height: 100%;
    overflow: auto;

    ${FooterInfos}, ${VisualizationsContainer}, ${FiltersContainer} {
        margin-top: ${theme.spacings.vertical15};
    }
    ${FooterInfos} {
        margin-bottom: ${theme.spacings.vertical15};
    }
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        /* leave space for footer and header */
        padding-top: ${theme.dimensions.headerMobileHeight};
        padding-bottom: ${theme.dimensions.footerMobileHeight}px;

        ${FooterInfos}, ${VisualizationsContainer}, ${TreeMap} {
            margin-top: ${theme.spacings.vertical1};
        }
        ${FooterInfos} {
            margin-bottom: ${theme.spacings.vertical1};
        }
    }
`
