import { scaleLinear } from 'd3-scale'
import { useSelector } from 'react-redux'
import {
    selectMapPuissanceToRadius,
    selectInstallationsPuissanceBounds,
} from '../../selectors/MapsContainer'
import { FiliereFilters } from '../../types'
import {
    mergeCanvases,
    generateHtml,
    generateFilieresLegendHtml,
    exportPdf,
} from '../../utils/export'
import { capitalize, formatValue } from '../../utils/format'
import { PuissanceBounds } from '../../utils/installations'

const LEGEND_SIZE_CIRCLE_COUNT = 5

const sampleValues = (valueBounds: PuissanceBounds) => {
    const expFunc = (x: number) => Math.pow(2, x)
    const scaleToValueBounds = scaleLinear()
        .domain([expFunc(0), expFunc(LEGEND_SIZE_CIRCLE_COUNT - 1)])
        .range(valueBounds)

    const valuesSample: Array<number> = []
    for (let i = 0; i < LEGEND_SIZE_CIRCLE_COUNT; i++) {
        valuesSample.push(scaleToValueBounds(expFunc(i)))
    }
    return valuesSample
}

export const useOnDownloadClickedCallback = (
    territoryName: string,
    filiereFilters: FiliereFilters
) => {
    const mapValueToRadius = useSelector(selectMapPuissanceToRadius)
    const valueBounds = useSelector(selectInstallationsPuissanceBounds)
    const valuesSample = sampleValues(valueBounds)
    const maxRadius = mapValueToRadius(valuesSample.slice(-1)[0])

    return (containerRef: React.MutableRefObject<any>) => {
        mergeCanvases(containerRef.current!).then((imgSrc) => {
            const htmlStr = generateHtml(
                'Localisation et puissance des installations',
                capitalize(territoryName),
                `<div class="map">
                    <img src="${imgSrc}" />
                    <div class="legend">
                        <div class="legendSizeInstallations">
                            ${valuesSample
                                .map((value) => {
                                    const radius = mapValueToRadius(value)
                                    return `
                                    <div class="legendSizeInstallationsItem">
                                        <div style="width:${maxRadius}px;">
                                            <div style="width:${radius}px;height:${radius}px;border-radius:${radius}px"></div>
                                        </div>
                                        <div>${formatValue(value, 'W', 1)}</div>
                                    </div>
                                `
                                })
                                .join('')}
                        </div>
                        ${generateFilieresLegendHtml(
                            filiereFilters.map((filiere) => ({ filiere }))
                        )}
                    </div>
                </div>`
            )
            exportPdf('graphique.pdf', htmlStr)
        })
    }
}
