import { Comma, Quote } from 'csv-string/dist/types'
import theme from './theme'
import { TerritoryType } from './types'

const ENV = process.env.REACT_APP_ENV

export default {
    // ---------- Base config
    PDF_EXPORT_TOKEN:
        ENV === 'production'
            ? 'U2FsdGVkX1+SYwU4E/IdSFmwk2lV9NlRTHBN2DfOL+2p2jWob9jAoCl0SEocEkHfJvPloTWd3k6IR/gJhv9JuDC8OQjhcRjNmbJcDeRrT64='
            : 'U2FsdGVkX1+P7K1XKQjsRxyOhxH9/Oi2tBZpBxqOIYRhyBkpj8sFtSQb7qF8fg+aaig5FhTpYkTRElv7HHoW71aS6QB+U8IfMd7b/awFChmRIQz4i8Xg+kB9e+FZLO6n7rW3Lh1ZObM35IqllgZqDfXSyIE/orFqu6LPJji5guk=',
    PDF_EXPORT_URL:
        ENV === 'production'
            ? 'https://api.lestrafiquants.tools/v1/pdf/agence-ore/html-to-pdf'
            : 'https://staging-api.lestrafiquants.tools/v1/pdf/agence-ore/html-to-pdf',
    SITE_URL: 'http://dataviz.agenceore.fr/biomethane-par-territoire',
    GOOGLE_WEB_FONTS: ['Open Sans'],
    ROOT_URL: ENV === 'production' ? '/biomethane-par-territoire' : '',
    CSV_QUOTE: '"' as Quote,
    CSV_DELIMITER: ',' as Comma,
    // ---------- Map config
    VISUALIZATION_PIXEL_SIZE: 1000,
    INFOBOX_DEBOUNCE_TIME: 20,
    MAP_CORSE_OFFSET: [0.6, -0.3] as [number, number],
    MAP_CORSE_LINE_FRANCE: [
        [6.532209, 42.575661],
        [8.396859, 43.825865],
    ] as [[number, number], [number, number]],
    // +/- pixels added to the pointer position for detecting
    // an installation in the flatbush index
    MAP_OVER_ZONE_PIXELS: 5,
    // Min / max size of the radius of canvas dots, given as a
    // proportion of the map size.
    PUISSANCE_MAPPING_WIDTH_RATIO: {
        [TerritoryType.PAYS]: [0.001, 0.03],
        [TerritoryType.REGION]: [0.002, 0.04],
        [TerritoryType.DEPARTEMENT]: [0.002, 0.05],
    },
    // Threshold in ratio of [min_puissance, max_puissance], for deciding
    // whether a dot should be on the big dots canvas.
    BIG_INSTALLATION_PUISSANCE_THRESHOLD: 0.3,
    // ---------- Misc
    PUISSANCE_MULT_WATTS: Math.pow(10, 9),
    NUMBERS_ROUND_DECIMAL: 2,
    TWITTER_TEXT:
        'Découvrez la carte des points d’injection de biométhane sur différentes mailles du territoire français | @agenceORE avec les données #ODRE @reseauxenergies',
}

// mapLayerZIndex forces some types of energies to display on top of others
export const FilieresConfig = {
    'Agricole autonome': {
        color: '#F5D25D',
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Agricole autonome',
    },
    'Industriel territorial': {
        color: '#00B4A0',
        colorFilterMobile: undefined,
        colorText: 'white',
        mapLayerZIndex: 3,
        display: 'Industriel territorial',
    },
    "Station d'épuration": {
        color: '#2F6D8F',
        colorFilterMobile: undefined,
        colorText: 'white',
        mapLayerZIndex: 4,
        display: "Station d'épuration",
    },
    'Agricole territorial': {
        color: '#ADBD4B',
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 2,
        display: 'Agricole territorial',
    },
    'Déchets ménagers': {
        color: '#638A59',
        colorFilterMobile: undefined,
        colorText: 'white',
        mapLayerZIndex: 5,
        display: 'Déchets ménagers',
    },
    ISDND: {
        color: '#B39D73',
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 6,
        display: 'ISDND',
    },
}
