import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { selectWindowDimensions } from '../../selectors/core'
import theme from '../../theme'
import { getCanvas2DContext } from '../../utils/react'

export interface Props {
    width: number
    height: number
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
    className?: string
}

const Canvas: React.FunctionComponent<Props> = ({
    width,
    height,
    canvasRef,
    className = '',
}) => {
    const { width: windowWidth } = useSelector(selectWindowDimensions)
    const pixelRatio =
        windowWidth > theme.dimensions.thresholdMobile
            ? // This is a little trick so that canvas would look good on any type of screen (e.g. retinas
              // which have a higher pixel density).
              // - We scale the drawing width/height of canvas with the pixel density
              // - We set the CSS width/height of the canvas to the desired size on screen.
              // - We set a transform so that everything drawn is scaled by the pixelRatio (and we can draw without thinking about it).
              // REF : https://stackoverflow.com/questions/24395076/canvas-generated-by-canvg-is-blurry-on-retina-screen
              window.devicePixelRatio || 1
            : // However if we are on a mobile, which can have high pixel densities, we want to avoid to take too high
              // canvas sizes to avoid reaching the canvas limits :
              // REF : https://stackoverflow.com/questions/6081483/maximum-size-of-a-canvas-element
              1

    useEffect(() => {
        const context = getCanvas2DContext(canvasRef)
        if (!context) {
            return
        }
        context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    })
    return (
        <canvas
            className={className}
            ref={canvasRef}
            height={height * pixelRatio}
            width={width * pixelRatio}
        />
    )
}

export default styled(React.memo(Canvas))`
    display: block;
`
