import React from 'react'
import { components, OptionProps } from 'react-select'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { Filiere } from '../../types'
import { ReactComponent as TickSvg } from '../../images/energy-tickbox.svg'
import { FilieresConfig } from '../../config'

export interface OptionData {
    filiere: Filiere
    isShowing: boolean
    disabled: boolean
}

const StyledOption = styled(components.Option)`
    &.ReactSelect__option {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 110%;
    }
    svg {
        display: block;
    }
`

const CheckBox = styled.div<OptionData>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.Black};
    height: 1.3em;
    width: 1.3em;
    padding: 0.15em;
    margin-right: ${theme.spacings.horizontal05};
    margin-left: ${theme.spacings.horizontal1};
    border-radius: 0.1em;
    ${(props) =>
        props.isShowing && props.isShowing && !props.disabled
            ? `background-color: ${
                  FilieresConfig[props.filiere].colorFilterMobile
                      ? FilieresConfig[props.filiere].colorFilterMobile
                      : FilieresConfig[props.filiere].color
              };`
            : ``}
    ${(props) =>
        props.disabled
            ? `
            border: 2px solid ${theme.colors.DarkBlueDarker};
            `
            : ``}
    ${(props) =>
        !props.isShowing && !props.disabled
            ? `
            border: 2px solid ${theme.colors.White};
            `
            : ``}
`

const Label = styled.div<OptionData>`
    ${(props) =>
        props.disabled
            ? `
            color: ${theme.colors.DarkBlueDarker};
            `
            : ``}
    ${(props) =>
        !props.isShowing && !props.disabled
            ? `
            text-decoration: line-through;
            `
            : ``}
`

const ReactSelectOption: React.FunctionComponent<OptionProps<OptionData>> = ({
    data,
    ...props
}) => {
    return (
        <StyledOption data={data} {...(props as any)}>
            <CheckBox {...(data as OptionData)}>
                {data.isShowing && !data.disabled ? <TickSvg /> : ''}
            </CheckBox>
            <Label {...(data as OptionData)}>
                {FilieresConfig[data.filiere as Filiere].display}
            </Label>
        </StyledOption>
    )
}

export default ReactSelectOption
