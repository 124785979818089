import React from 'react'
import styled from 'styled-components/macro'
import { InfoBoxSectionInfoData } from '../../selectors/InfoBoxes'
import theme from '../../theme'

export interface Props {
    infoBoxData: InfoBoxSectionInfoData
    className?: string
}

const InfoBoxSectionInfo: React.FunctionComponent<Props> = ({
    infoBoxData,
    className = '',
}) => {
    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{ __html: infoBoxData.text }}
        ></div>
    )
}

export default styled(React.memo(InfoBoxSectionInfo))`
    font-size: 96%;
    max-width: 26em;
    line-height: 1.25em;
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal1};
`
